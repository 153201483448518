.elementor-13 .elementor-element.elementor-element-cde7a8f:not(.elementor-motion-effects-element-type-background), .elementor-13 .elementor-element.elementor-element-cde7a8f > .elementor-motion-effects-container > .elementor-motion-effects-layer {
    background-image: url("https://hbwsl.com/wp-content/uploads/2023/08/2023-03-16.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.elementor-13 .elementor-element.elementor-element-cde7a8f > .elementor-background-overlay {
    background-color: #192C51;
    opacity: 0.9;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-13 .elementor-element.elementor-element-cde7a8f {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 200px 0px 150px 0px;
}

.elementor-13 .elementor-element.elementor-element-d7046da {
    text-align: center;
}

.elementor-13 .elementor-element.elementor-element-ee508b5 {
    padding: 100px 0px 100px 0px;
}

.elementor-13 .elementor-element.elementor-element-15aafd6 > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-d24b1ae {
    padding: 0px 0px 100px 0px;
}

.elementor-13 .elementor-element.elementor-element-1a2d4ef > .elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 025px 0px 0px;
    --e-column-margin-right: 025px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-1a2d4ef > .elementor-element-populated > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-13 .elementor-element.elementor-element-f70ecfa > .elementor-element-populated {
    margin: 0px 0px 0px 025px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 025px;
    padding: 0px 0px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-6a11774 .elementor-heading-title {
    color: #212D45;
    font-size: 18px;
    font-weight: 600;
}

.elementor-13 .elementor-element.elementor-element-1128a50 .elementor-heading-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-13 .elementor-element.elementor-element-a79e8e9 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(10px/2);
}

.elementor-13 .elementor-element.elementor-element-a79e8e9 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(10px/2);
}

.elementor-13 .elementor-element.elementor-element-a79e8e9 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item {
    margin-right: calc(10px/2);
    margin-left: calc(10px/2);
}

.elementor-13 .elementor-element.elementor-element-a79e8e9 .elementor-icon-list-items.elementor-inline-items {
    margin-right: calc(-10px/2);
    margin-left: calc(-10px/2);
}

body.rtl .elementor-13 .elementor-element.elementor-element-a79e8e9 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    left: calc(-10px/2);
}

body:not(.rtl) .elementor-13 .elementor-element.elementor-element-a79e8e9 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    right: calc(-10px/2);
}

.elementor-13 .elementor-element.elementor-element-a79e8e9 .elementor-icon-list-icon i {
    color: #212D45;
    transition: color 0.3s;
}

.elementor-13 .elementor-element.elementor-element-a79e8e9 .elementor-icon-list-icon svg {
    fill: #212D45;
    transition: fill 0.3s;
}

.elementor-13 .elementor-element.elementor-element-a79e8e9 {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
}

.elementor-13 .elementor-element.elementor-element-a79e8e9 .elementor-icon-list-item > .elementor-icon-list-text, .elementor-13 .elementor-element.elementor-element-a79e8e9 .elementor-icon-list-item > a {
    font-size: 17px;
    text-transform: capitalize;
    line-height: 1.4em;
}

.elementor-13 .elementor-element.elementor-element-a79e8e9 .elementor-icon-list-text {
    transition: color 0.3s;
}

.elementor-13 .elementor-element.elementor-element-f5130a0 {
    padding: 0px 0px 100px 0px;
}

.elementor-13 .elementor-element.elementor-element-39e93f6 > .elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 025px 0px 0px;
    --e-column-margin-right: 025px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-39e93f6 > .elementor-element-populated > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-13 .elementor-element.elementor-element-4b242f5 > .elementor-element-populated {
    margin: 0px 0px 0px 025px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 025px;
    padding: 0px 0px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-e2d512e .elementor-heading-title {
    color: #212D45;
    font-size: 18px;
    font-weight: 600;
}

.elementor-13 .elementor-element.elementor-element-cd0bc37 .elementor-heading-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-13 .elementor-element.elementor-element-2faf06e .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(10px/2);
}

.elementor-13 .elementor-element.elementor-element-2faf06e .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(10px/2);
}

.elementor-13 .elementor-element.elementor-element-2faf06e .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item {
    margin-right: calc(10px/2);
    margin-left: calc(10px/2);
}

.elementor-13 .elementor-element.elementor-element-2faf06e .elementor-icon-list-items.elementor-inline-items {
    margin-right: calc(-10px/2);
    margin-left: calc(-10px/2);
}

body.rtl .elementor-13 .elementor-element.elementor-element-2faf06e .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    left: calc(-10px/2);
}

body:not(.rtl) .elementor-13 .elementor-element.elementor-element-2faf06e .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    right: calc(-10px/2);
}

.elementor-13 .elementor-element.elementor-element-2faf06e .elementor-icon-list-icon i {
    color: #212D45;
    transition: color 0.3s;
}

.elementor-13 .elementor-element.elementor-element-2faf06e .elementor-icon-list-icon svg {
    fill: #212D45;
    transition: fill 0.3s;
}

.elementor-13 .elementor-element.elementor-element-2faf06e {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
}

.elementor-13 .elementor-element.elementor-element-2faf06e .elementor-icon-list-item > .elementor-icon-list-text, .elementor-13 .elementor-element.elementor-element-2faf06e .elementor-icon-list-item > a {
    font-size: 17px;
    text-transform: capitalize;
    line-height: 1.4em;
}

.elementor-13 .elementor-element.elementor-element-2faf06e .elementor-icon-list-text {
    transition: color 0.3s;
}

.elementor-13 .elementor-element.elementor-element-8d8c841 {
    padding: 0px 0px 100px 0px;
}

.elementor-13 .elementor-element.elementor-element-326a2dc > .elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 025px 0px 0px;
    --e-column-margin-right: 025px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-326a2dc > .elementor-element-populated > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-13 .elementor-element.elementor-element-3663938 > .elementor-element-populated {
    margin: 0px 0px 0px 025px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 025px;
    padding: 0px 0px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-7d47d11 .elementor-heading-title {
    color: #212D45;
    font-size: 18px;
    font-weight: 600;
}

.elementor-13 .elementor-element.elementor-element-1d0c434 .elementor-heading-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-13 .elementor-element.elementor-element-c859382 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(10px/2);
}

.elementor-13 .elementor-element.elementor-element-c859382 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(10px/2);
}

.elementor-13 .elementor-element.elementor-element-c859382 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item {
    margin-right: calc(10px/2);
    margin-left: calc(10px/2);
}

.elementor-13 .elementor-element.elementor-element-c859382 .elementor-icon-list-items.elementor-inline-items {
    margin-right: calc(-10px/2);
    margin-left: calc(-10px/2);
}

body.rtl .elementor-13 .elementor-element.elementor-element-c859382 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    left: calc(-10px/2);
}

body:not(.rtl) .elementor-13 .elementor-element.elementor-element-c859382 .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    right: calc(-10px/2);
}

.elementor-13 .elementor-element.elementor-element-c859382 .elementor-icon-list-icon i {
    color: #212D45;
    transition: color 0.3s;
}

.elementor-13 .elementor-element.elementor-element-c859382 .elementor-icon-list-icon svg {
    fill: #212D45;
    transition: fill 0.3s;
}

.elementor-13 .elementor-element.elementor-element-c859382 {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
}

.elementor-13 .elementor-element.elementor-element-c859382 .elementor-icon-list-item > .elementor-icon-list-text, .elementor-13 .elementor-element.elementor-element-c859382 .elementor-icon-list-item > a {
    font-size: 17px;
    text-transform: capitalize;
    line-height: 1.4em;
}

.elementor-13 .elementor-element.elementor-element-c859382 .elementor-icon-list-text {
    transition: color 0.3s;
}

.elementor-13 .elementor-element.elementor-element-294aafc {
    padding: 0px 0px 100px 0px;
}

.elementor-13 .elementor-element.elementor-element-9205e45 > .elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0px 025px 0px 0px;
    --e-column-margin-right: 025px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-9205e45 > .elementor-element-populated > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-13 .elementor-element.elementor-element-b7666c9 > .elementor-element-populated {
    margin: 0px 0px 0px 025px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 025px;
    padding: 0px 0px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-c823da2 .elementor-heading-title {
    color: #212D45;
    font-size: 18px;
    font-weight: 600;
}

.elementor-13 .elementor-element.elementor-element-b64289b .elementor-heading-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-13 .elementor-element.elementor-element-ee6267f .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(10px/2);
}

.elementor-13 .elementor-element.elementor-element-ee6267f .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(10px/2);
}

.elementor-13 .elementor-element.elementor-element-ee6267f .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item {
    margin-right: calc(10px/2);
    margin-left: calc(10px/2);
}

.elementor-13 .elementor-element.elementor-element-ee6267f .elementor-icon-list-items.elementor-inline-items {
    margin-right: calc(-10px/2);
    margin-left: calc(-10px/2);
}

body.rtl .elementor-13 .elementor-element.elementor-element-ee6267f .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    left: calc(-10px/2);
}

body:not(.rtl) .elementor-13 .elementor-element.elementor-element-ee6267f .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    right: calc(-10px/2);
}

.elementor-13 .elementor-element.elementor-element-ee6267f .elementor-icon-list-icon i {
    color: #212D45;
    transition: color 0.3s;
}

.elementor-13 .elementor-element.elementor-element-ee6267f .elementor-icon-list-icon svg {
    fill: #212D45;
    transition: fill 0.3s;
}

.elementor-13 .elementor-element.elementor-element-ee6267f {
    --e-icon-list-icon-size: 14px;
    --icon-vertical-offset: 0px;
}

.elementor-13 .elementor-element.elementor-element-ee6267f .elementor-icon-list-item > .elementor-icon-list-text, .elementor-13 .elementor-element.elementor-element-ee6267f .elementor-icon-list-item > a {
    font-size: 17px;
    text-transform: capitalize;
    line-height: 1.4em;
}

.elementor-13 .elementor-element.elementor-element-ee6267f .elementor-icon-list-text {
    transition: color 0.3s;
}

.elementor-13 .elementor-element.elementor-element-0d3a0da:not(.elementor-motion-effects-element-type-background), .elementor-13 .elementor-element.elementor-element-0d3a0da > .elementor-motion-effects-container > .elementor-motion-effects-layer {
    background-color: #192C51;
}

.elementor-13 .elementor-element.elementor-element-0d3a0da {
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #FFFFFF;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 80px 0px 50px 0px;
}

.elementor-13 .elementor-element.elementor-element-0d3a0da > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-bc-flex-widget .elementor-13 .elementor-element.elementor-element-7980da1.elementor-column .elementor-widget-wrap {
    align-items: flex-start;
}

.elementor-13 .elementor-element.elementor-element-7980da1.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated {
    align-content: flex-start;
    align-items: flex-start;
}

.elementor-13 .elementor-element.elementor-element-7980da1.elementor-column > .elementor-widget-wrap {
    justify-content: flex-start;
}

.elementor-13 .elementor-element.elementor-element-7980da1 > .elementor-widget-wrap > .elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 10px;
}

.elementor-13 .elementor-element.elementor-element-7980da1 > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-a87d65b {
    width: auto;
    max-width: auto;
}

.elementor-13 .elementor-element.elementor-element-89bec23 {
    text-align: left;
}

.elementor-13 .elementor-element.elementor-element-89bec23 .elementor-heading-title {
    color: #FFFFFF;
    font-size: 19px;
    font-weight: 700;
    line-height: 1.5em;
}

.elementor-13 .elementor-element.elementor-element-89bec23 > .elementor-widget-container {
    margin: 0px 0px 20px 0px;
    padding: 0px 050px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-57a4bc1 .elementor-icon-list-icon i {
    color: #FFC03D;
    transition: color 0.3s;
}

.elementor-13 .elementor-element.elementor-element-57a4bc1 .elementor-icon-list-icon svg {
    fill: #FFC03D;
    transition: fill 0.3s;
}

.elementor-13 .elementor-element.elementor-element-57a4bc1 .elementor-icon-list-item:hover .elementor-icon-list-icon i {
    color: #F8B526;
}

.elementor-13 .elementor-element.elementor-element-57a4bc1 .elementor-icon-list-item:hover .elementor-icon-list-icon svg {
    fill: #F8B526;
}

.elementor-13 .elementor-element.elementor-element-57a4bc1 {
    --e-icon-list-icon-size: 20px;
    --icon-vertical-offset: 0px;
}

.elementor-13 .elementor-element.elementor-element-57a4bc1 .elementor-icon-list-text {
    transition: color 0.3s;
}

.elementor-bc-flex-widget .elementor-13 .elementor-element.elementor-element-2bdd435.elementor-column .elementor-widget-wrap {
    align-items: flex-start;
}

.elementor-13 .elementor-element.elementor-element-2bdd435.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated {
    align-content: flex-start;
    align-items: flex-start;
}

.elementor-13 .elementor-element.elementor-element-2bdd435.elementor-column > .elementor-widget-wrap {
    justify-content: flex-start;
}

.elementor-13 .elementor-element.elementor-element-2bdd435 > .elementor-widget-wrap > .elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 10px;
}

.elementor-13 .elementor-element.elementor-element-2bdd435 > .elementor-element-populated {
    margin: 0px 0px 0px -20px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: -20px;
    padding: 0px 50px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-e996ff1 .elementor-heading-title {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5em;
}

.elementor-13 .elementor-element.elementor-element-1e5f7bb .elementor-heading-title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3em;
}

.elementor-13 .elementor-element.elementor-element-b9d6a49 .elementor-heading-title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3em;
}

.elementor-13 .elementor-element.elementor-element-c26716c .elementor-heading-title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3em;
}

.elementor-13 .elementor-element.elementor-element-d27ac35 .elementor-heading-title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3em;
}

.elementor-13 .elementor-element.elementor-element-c7e9fba .elementor-heading-title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3em;
}

.elementor-13 .elementor-element.elementor-element-5e2a269 .elementor-heading-title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3em;
}

.elementor-13 .elementor-element.elementor-element-8387d43 .elementor-heading-title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3em;
}

.elementor-bc-flex-widget .elementor-13 .elementor-element.elementor-element-bab3466.elementor-column .elementor-widget-wrap {
    align-items: flex-start;
}

.elementor-13 .elementor-element.elementor-element-bab3466.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated {
    align-content: flex-start;
    align-items: flex-start;
}

.elementor-13 .elementor-element.elementor-element-bab3466.elementor-column > .elementor-widget-wrap {
    justify-content: center;
}

.elementor-13 .elementor-element.elementor-element-bab3466 > .elementor-widget-wrap > .elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 10px;
}

.elementor-13 .elementor-element.elementor-element-bab3466 > .elementor-element-populated {
    margin: 0px 0px 0px -50px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: -50px;
    padding: 0px 50px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-9d25ef6 .elementor-heading-title {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5em;
}

.elementor-13 .elementor-element.elementor-element-e9598f9 .elementor-heading-title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3em;
}

.elementor-13 .elementor-element.elementor-element-5edbbe5 .elementor-heading-title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3em;
}

.elementor-bc-flex-widget .elementor-13 .elementor-element.elementor-element-7a5b0b9.elementor-column .elementor-widget-wrap {
    align-items: flex-start;
}

.elementor-13 .elementor-element.elementor-element-7a5b0b9.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated {
    align-content: flex-start;
    align-items: flex-start;
}

.elementor-13 .elementor-element.elementor-element-7a5b0b9 > .elementor-widget-wrap > .elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 10px;
}

.elementor-13 .elementor-element.elementor-element-7a5b0b9 > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
}

.elementor-13 .elementor-element.elementor-element-c26de7c .elementor-heading-title {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5em;
}

@media(max-width: 1024px) {
    .elementor-13 .elementor-element.elementor-element-1a2d4ef > .elementor-element-populated {
        margin:0px 0px 40px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-bc-flex-widget .elementor-13 .elementor-element.elementor-element-f70ecfa.elementor-column .elementor-widget-wrap {
        align-items: center;
    }

    .elementor-13 .elementor-element.elementor-element-f70ecfa.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated {
        align-content: center;
        align-items: center;
    }

    .elementor-13 .elementor-element.elementor-element-f70ecfa.elementor-column > .elementor-widget-wrap {
        justify-content: center;
    }

    .elementor-13 .elementor-element.elementor-element-f70ecfa > .elementor-element-populated {
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-13 .elementor-element.elementor-element-6a11774 {
        text-align: left;
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-fbdb8ac {
        text-align: left;
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-1128a50 {
        text-align: left;
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-a79e8e9 {
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-39e93f6 > .elementor-element-populated {
        margin: 0px 0px 40px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-bc-flex-widget .elementor-13 .elementor-element.elementor-element-4b242f5.elementor-column .elementor-widget-wrap {
        align-items: center;
    }

    .elementor-13 .elementor-element.elementor-element-4b242f5.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated {
        align-content: center;
        align-items: center;
    }

    .elementor-13 .elementor-element.elementor-element-4b242f5.elementor-column > .elementor-widget-wrap {
        justify-content: center;
    }

    .elementor-13 .elementor-element.elementor-element-4b242f5 > .elementor-element-populated {
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-13 .elementor-element.elementor-element-e2d512e {
        text-align: left;
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-97f1027 {
        text-align: left;
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-cd0bc37 {
        text-align: left;
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-2faf06e {
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-326a2dc > .elementor-element-populated {
        margin: 0px 0px 40px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-bc-flex-widget .elementor-13 .elementor-element.elementor-element-3663938.elementor-column .elementor-widget-wrap {
        align-items: center;
    }

    .elementor-13 .elementor-element.elementor-element-3663938.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated {
        align-content: center;
        align-items: center;
    }

    .elementor-13 .elementor-element.elementor-element-3663938.elementor-column > .elementor-widget-wrap {
        justify-content: center;
    }

    .elementor-13 .elementor-element.elementor-element-3663938 > .elementor-element-populated {
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-13 .elementor-element.elementor-element-7d47d11 {
        text-align: left;
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-c325671 {
        text-align: left;
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-1d0c434 {
        text-align: left;
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-c859382 {
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-9205e45 > .elementor-element-populated {
        margin: 0px 0px 40px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-bc-flex-widget .elementor-13 .elementor-element.elementor-element-b7666c9.elementor-column .elementor-widget-wrap {
        align-items: center;
    }

    .elementor-13 .elementor-element.elementor-element-b7666c9.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated {
        align-content: center;
        align-items: center;
    }

    .elementor-13 .elementor-element.elementor-element-b7666c9.elementor-column > .elementor-widget-wrap {
        justify-content: center;
    }

    .elementor-13 .elementor-element.elementor-element-b7666c9 > .elementor-element-populated {
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-13 .elementor-element.elementor-element-c823da2 {
        text-align: left;
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-a67717b {
        text-align: left;
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-b64289b {
        text-align: left;
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-ee6267f {
        width: var( --container-widget-width, 640px );
        max-width: 640px;
        --container-widget-width: 640px;
        --container-widget-flex-grow: 0;
    }

    .elementor-13 .elementor-element.elementor-element-0d3a0da {
        padding: 80px 10px 50px 10px;
    }

    .elementor-13 .elementor-element.elementor-element-2bdd435 > .elementor-element-populated {
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-13 .elementor-element.elementor-element-bab3466.elementor-column > .elementor-widget-wrap {
        justify-content: flex-start;
    }

    .elementor-13 .elementor-element.elementor-element-bab3466 > .elementor-element-populated {
        margin: 0px 0px 0px -10px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: -10px;
        padding: 0px 0px 0px 0px;
    }
}

@media(max-width: 767px) {
    .elementor-13 .elementor-element.elementor-element-ee508b5 {
        padding:100px 0px 100px 0px;
    }

    .elementor-13 .elementor-element.elementor-element-15aafd6 > .elementor-element-populated {
        padding: 0px 20px 0px 20px;
    }

    .elementor-13 .elementor-element.elementor-element-f70ecfa > .elementor-element-populated {
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-13 .elementor-element.elementor-element-6a11774 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-fbdb8ac {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-1128a50 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-4b242f5 > .elementor-element-populated {
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-13 .elementor-element.elementor-element-e2d512e {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-97f1027 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-cd0bc37 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-3663938 > .elementor-element-populated {
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-13 .elementor-element.elementor-element-7d47d11 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-c325671 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-1d0c434 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-b7666c9 > .elementor-element-populated {
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-13 .elementor-element.elementor-element-c823da2 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-a67717b {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-b64289b {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-7980da1.elementor-column > .elementor-widget-wrap {
        justify-content: center;
    }

    .elementor-13 .elementor-element.elementor-element-7980da1 > .elementor-element-populated {
        margin: 0px 0px 30px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-13 .elementor-element.elementor-element-89bec23 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-2bdd435 > .elementor-element-populated {
        margin: 0px 0px 30px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
        padding: 0px 0px 0px 0px;
    }

    .elementor-13 .elementor-element.elementor-element-e996ff1 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-1e5f7bb {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-b9d6a49 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-c26716c {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-d27ac35 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-c7e9fba {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-5e2a269 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-8387d43 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-bab3466 > .elementor-element-populated {
        margin: 0px 0px 30px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-13 .elementor-element.elementor-element-9d25ef6 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-e9598f9 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-5edbbe5 {
        text-align: center;
    }

    .elementor-13 .elementor-element.elementor-element-c26de7c {
        text-align: center;
    }
}

@media(max-width: 1024px) and (min-width:768px) {
    .elementor-13 .elementor-element.elementor-element-1a2d4ef {
        width:100%;
    }

    .elementor-13 .elementor-element.elementor-element-f70ecfa {
        width: 100%;
    }

    .elementor-13 .elementor-element.elementor-element-39e93f6 {
        width: 100%;
    }

    .elementor-13 .elementor-element.elementor-element-4b242f5 {
        width: 100%;
    }

    .elementor-13 .elementor-element.elementor-element-326a2dc {
        width: 100%;
    }

    .elementor-13 .elementor-element.elementor-element-3663938 {
        width: 100%;
    }

    .elementor-13 .elementor-element.elementor-element-9205e45 {
        width: 100%;
    }

    .elementor-13 .elementor-element.elementor-element-b7666c9 {
        width: 100%;
    }
}
